import React from 'react'
import { TopBanner } from '../components/TopBanner'
import { useState } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Alert from '@mui/material/Alert'

import LoginIcon from '@mui/icons-material/Login'

const Page = () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('myauth')
  }
  const authUrl = process.env.GATSBY_AUTH_URL
  //const authUrl = 'https://auth-test.mehilainen.fi'

  //console.log('auth: ' + authUrl)
  const [errorStatus, setErrorStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const isBrowser = typeof window !== 'undefined'

  function login() {
    if (!isBrowser) {
      return
    }
    const auth = authUrl + '/azure-ad/spa/login'
    const origin = encodeURIComponent(window.location.href)
    window.open(`${auth}?origin=${origin}`, 'Login', 'dependent=1,dialog=1')
  }

  // add error handling
  if (isBrowser) {
    window.addEventListener('message', event => {
      if (event.origin === authUrl) {
        //if (event.origin === 'https://auth-test.mehilainen.fi') {
        const data = JSON.parse(event.data)
        if (data.type === 'adjwt') {
          if (data.error) {
            setErrorStatus(true)
            setErrorMessage('Ongelma kirjautumisessa, yritä uudelleen') //palauta virheviesti 1, virheviesti 2 tai geneerinen
            //return console.log(data.error)
          }
          if (data.token && isBrowser) {
            localStorage.setItem('myauth', data.token)
            //console.log(data.token)
            navigate('/action')
          }
        }
      }
    })
  }

  return (
    <>
      <div>
        <TopBanner user="" />
      </div>
      <Container>
        {errorStatus ? (
          <div>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              Virhetilanne: {errorMessage}
            </Alert>
          </div>
        ) : (
          ''
        )}
        <PageTitle>Aloitussivu</PageTitle>
        <Button
          id="use"
          variant="contained"
          size="large"
          onClick={login}
          endIcon={<LoginIcon />}
        >
          Kirjaudu järjestelmään
        </Button>
      </Container>
    </>
  )
}

export default Page

const PageTitle = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
  font-size: 1.5rem;
  font weight: 700;
  padding: 40px;
`

const Container = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`
